import React from "react";
// Customizable Area Start
import {
  createTheme,
} from "@mui/material/styles";
import { Pressable, ScrollView, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, Platform } from "react-native";
import { Typography} from "@builder/component-library";
import StarIcon from '@mui/icons-material/StarOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotMatchingIcon from '@mui/icons-material/ErrorOutlineOutlined';
const config = require("../../../framework/src/config");
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <>
        <View style={styles.advancedSearchContainerHeader}>
          <Typography style={styles.advancedSearchContainerHeaderTitle} variant="xl">Store locator</Typography>

          <View style={styles.advancedSearchContainerHeaderActions}>
            <TouchableOpacity testID="btnCloseAdvancedSearch" onPress={() => this.props.handleCloseAdvancedSearch(this.state.inputSearchText)}>
              <CloseOutlinedIcon sx={styles.advancedSearchContainerHeaderIcon} />
            </TouchableOpacity>
          </View>
        </View>


        <TouchableWithoutFeedback testID="inputSearchContainer" onPress={this.handleFocusInputSearch}>
          <View style={styles.inputSearchContainer}>
            <SearchOutlinedIcon sx={styles.inputSearchIcon} />
            <input 
              id="inputSeach"
              ref={this.inputSearchRef} 
              style={this.isPlatformWeb() ? {...styles.inputSearch, borderWidth: 0, outlineStyle: "none" } : styles.inputSearch} 
              placeholder="Search" 
              value={this.state.inputSearchText}
              {...this.inputSearchProps}
            />
            <TouchableOpacity testID="btnClearInput" onPress={this.handleClearInput}>
              <CloseOutlinedIcon sx={styles.advancedSearchContainerHeaderIcon} />
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>


        <ScrollView style={styles.storeLocatorContainer}>
          {
            this.props.stores.length > 0 ? this.props.stores.map((store) => {
              return (
                <Pressable testID={`btnGoToBusinessDetail-${store.id}`} key={store.id} style={styles.storeLocatorContent} onPress={() => this.props.onOpenBusinessDetails(store)}>
                  <img 
                    style={styles.storeLocatorImage}
                    src={config.baseURL + store.profile_image[0]?.url || ""}  
                  /> 
                  <View style={styles.storeLocatorInfo}>
                    <View style={styles.storeLocatorHeader}>
                      <Typography variant="base" style={styles.storeLocatorName}>{store.user_name}</Typography>
                      
                      <Typography variant="xs" style={styles.storeLocatorOpenButtonText}>{store.show_open ? "Open" : "Close"}</Typography>
                    </View>

                    <Typography variant="xs" style={styles.storeLocatorAddress}>{store.address}</Typography>
                  </View>
                </Pressable>
              )
            }) : (
              <View style={styles.noStoreResultContainer}>
                <View style={styles.noStoreResultIconContainer}>
                  <NotMatchingIcon sx={styles.noStoreResultIcon} />
                </View>

                <Typography style={styles.noStoreRestulText} variant="2xl">No result found</Typography>
                
                <TouchableOpacity testID="btnResetSearch" onPress={this.handleClearInput} style={styles.noStoreResultResetSearchButton}>
                  <Typography style={styles.noStoreResultResetSearchButtonText} variant="sm">Reset search</Typography>
                </TouchableOpacity>
              </View>
            )
          }
        </ScrollView>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  advancedSearchContainerHeader: {
    flexDirection: "row",
    marginTop: 36,
    paddingHorizontal: 24,
    alignItems: "center"
  },
  advancedSearchContainerHeaderTitle: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#1E293B"
  },
  advancedSearchContainerHeaderIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  advancedSearchContainerHeaderActions: {
    flexDirection: "row",
    marginLeft: "auto"
  },
  advancedSearchContainerHeaderActionButton: {
    marginRight: 8
  },
  inputSearchContainer: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginHorizontal: 20,
    marginTop: 26,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  advancedSearchContainer: {
    marginTop: 25,
  },
  inputSearchIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  inputSearch: {
    flex: 1,
    marginLeft: 8,
    color: "#1E293B",
    fontSize: 14
  },
  storeLocatorContainer: {
    marginTop: 26,
  },
  storeLocatorContent: {
    paddingVertical: 14,
    marginHorizontal: 12,
    flexDirection: "row",
    borderBottomColor: "#FFFFFF4D",
    borderBottomWidth: 1,
  },
  storeLocatorImage: {
    width: 90,
    height: 84,
    resizeMode: "contain",
    borderRadius: 16,
    paddingVertical: 7,
  },
  storeLocatorInfo: {
    marginLeft: 16,
    flex: 1,
  },
  storeLocatorHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  storeLocatorName: {
    fontWeight: "700",
    color: "#0F172A"
  },
  storeLocatorOpenButtonText: {
    fontWeight: "500",
    color: "#8861A9",
    paddingHorizontal: 20,
  },
  storeLocatorAddress: {
    fontWeight: "400",
    color: "#0F172A",
    ...Platform.select({
      web: {
        wordBreak: "break-all"
      },
      default: {}
    })
  },
  noStoreResultContainer: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    marginTop: 120
  },
  noStoreResultIconContainer: {
    width: 56,
    height: 56,
    borderRadius: 200,
    backgroundColor: "#FEE2E2",
    alignItems: "center",
    justifyContent: "center"
  },
  noStoreResultIcon: {
    fontSize: 26,
    color: "#0F172A"
  },
  noStoreRestulText: {
    fontWeight: "700",
    color: "#0F172A",
    marginTop: 16
  },
  noStoreResultResetSearchButton: {
    marginTop: 18
  },
  noStoreResultResetSearchButtonText: {
    fontWeight: "700",
    color: "#8861A9"
  },
})
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type City = {
  id: string;
  type: string;
  attributes: {
    latitude: number;
    longitude: number;
    city_name: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCity: City;
  cities: Array<City>;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      selectedCity: {} as City,
      cities: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.getCities(token);
    }
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  citiesApiCallId: string = "";

  async componentDidMount() {
    this.getToken();
  }

  private handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
    if (apiRequestCallId === this.citiesApiCallId && responseJson) {
      this.processCityResponse(responseJson);
    }
  }
  
  private processCityResponse(responseJson: any) {
    if (responseJson.errors) {
      this.showAlert('Error', 'Something went wrong');
    } else if (responseJson.data) {
      this.setState({
        cities: responseJson.data,
        selectedCity: responseJson.data[0]
      });
    }
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  getCities(token: string) {
    const headers = {
      "Content-Type": configJSON.getCitiesApiContentType,
      token
    };
    
    const getCitiessMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesApiCallId = getCitiessMsg.messageId;

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCitiesAPiEndPoint
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getCitiessMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCitiesAPiMethod
    );

    runEngine.sendMessage(getCitiessMsg.id, getCitiessMsg);
  }

  goToMaps() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMapsMessage)
    );
    
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      latitude: this.state.selectedCity.attributes.latitude,
      longitude: this.state.selectedCity.attributes.longitude,
      city_name: this.state.selectedCity.attributes.city_name
    });
    

    this.send(message);
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}

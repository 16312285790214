import React from "react";
// Customizable Area Start
import { Platform, Pressable, ScrollView, StyleSheet, Switch, TouchableOpacity, View } from "react-native";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppHeader from "../../../components/src/AppHeader.web";
import { Typography, Button, Input } from "@builder/component-library";
const DatePicker = require("react-datepicker").default;
import "react-datepicker/dist/react-datepicker.css";
import CloudUploadIcon from '@mui/icons-material/CloudUploadOutlined';
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { imgVisbility, imgVisbilityOff } from "./assets";
const dayOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
import Select from "react-select";
// Customizable Area End

import UpdateBusinessInformationController, {
  Props,
} from "./UpdateBusinessInformationController";
// Customizable Area End

export default class UpdateBusinessInformation extends UpdateBusinessInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={styles.screen}>

        <AppHeader {...this.props} />

        <ScrollView style={styles.scroll} ref={this.updateBusinessInfoScrollRef}>
          <View style={styles.main}>  
            {
              this.state.alertVisibility && (
                <View style={styles.alertContainer}>
                  <Typography style={styles.alertText}>{this.state.alertMessage}</Typography>
                </View>
              )
            }    

            <View style={styles.header}>
              <TouchableOpacity testID="btnGoBack" {...this.btnGoBack} style={styles.goBackButton}>
                <ArrowBackIcon style={styles.goBackButtonIcon} />
              </TouchableOpacity>

              <Typography variant="xl" style={styles.headerText}>Business information</Typography>
            </View>

            <View style={styles.content}>
              <View style={styles.uploadContainer}>
                <Pressable testID="btnUploadPicutures" style={styles.uploadButtton} onPress={this.handleOpenSelectProfilePicture}>
                  <input 
                    id="inputFile"
                    type="file"
                    ref={this.inputProfilePictureRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple
                    onChange={this.handleSelectedFile}
                  />
                  <CloudUploadIcon sx={styles.couldIcon} />
                  <Typography variant="base" style={styles.uploadTitle}>Upload media</Typography>
                  <Typography variant="sm" style={styles.uploadSubTitle}>Add some files</Typography>
                  <View style={styles.uploadHighlightContainer}>
                    <FileUploadIcon sx={styles.uploadHighlightIcon} />
                    <Typography variant="base" style={styles.uploadHighlightText}>Upload from your device</Typography>
                  </View>           
                </Pressable>

                <View style={styles.uploadedImageContainerList}>
                  {
                    this.state.uploadedPictures.map((picture, index) => {
                      return (
                        <View key={index} style={styles.uploadedImageContainer}>
                          <TouchableOpacity testID={"btnDeleteUploadedPicutre-" + index} style={styles.removeUploadedImageButton} onPress={() => this.handleRemovePicture(index)}>
                            <CloseIcon sx={styles.removeUploadedImageButtonIcon} />
                          </TouchableOpacity>

                          <img src={picture} style={styles.uploadedImage} />
                        </View>
                      )
                    })
                  }
                  <View>

                  </View>
                </View>
              </View>

              <View style={styles.formContainer}>
                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Name</Typography>
                  <Input 
                    testID="txtInputName"
                    containerStyle={styles.input}
                    value={this.state.fullName}
                    {...this.txtInputNameProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Email</Typography>
                  <Input 
                    testID="txtInputEmail"
                    containerStyle={styles.input}
                    value={this.state.email}
                    {...this.txtInputEmailProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Street address</Typography>
                  <Input 
                    testID="txtInputStreet"
                    containerStyle={styles.input}
                    value={this.state.street}
                    {...this.txtInputStreetProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>City</Typography>
                  <Input 
                    testID="txtInputCity"
                    containerStyle={styles.input}
                    value={this.state.city}
                    {...this.txtInputCityProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>State</Typography>
                  <Input 
                    testID="txtInputState"
                    containerStyle={styles.input}
                    value={this.state.state}
                    {...this.txtInputStateProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Country</Typography>
                  <Input 
                    testID="txtInputCountry"
                    containerStyle={styles.input}
                    value={this.state.country}
                    {...this.txtInputCountryProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Zip code</Typography>
                  <Input 
                    testID="txtInputZipcode"
                    containerStyle={styles.input}
                    value={this.state.zipcode}
                    {...this.txtInputZipcodeProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Bio</Typography>
                  <Input 
                    testID="txtInputBio"
                    multiline
                    containerStyle={styles.textareaContainer}
                    maxLength={200}
                    style={styles.textarea}
                    value={this.state.bio}
                    {...this.txtInputBioProps}
                  />
                  <Typography variant="xs" style={styles.bioLengthText}>{this.state.bio.length.toString()}/200</Typography>
                </View>                  

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Operating hours</Typography>
                  {
                    dayOfWeek.map((day) => {
                      return (
                        <View key={day} style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: 20 }}>
                          <Typography variant="sm" style={{...styles.label, flex: 1 }}>{day}</Typography>

                          <Switch 
                            testID={`switch-openclose-${day.toLowerCase()}`}
                            value={this.state.operatingHours[day.toLowerCase()]?.open}
                            onValueChange={(value) => this.handleUpadateOperatingHoursOpenClose(value, day)}
                            thumbColor={"#FFF"}
                            {...Platform.select({web: {
                              activeThumbColor: "white"
                            }})}
                            trackColor={{
                              true: "#8861A9",
                              false: "#AAA"
                            }}
                          />

                          <View style={{ flexDirection: "row" }}>
                            <DatePicker
                              testID={`timepicker-start-${day.toLowerCase()}`}
                              customInput={
                                <Input containerStyle={styles.input} />
                              }
                              selected={this.state.operatingHours[day.toLowerCase()]?.start_hour}
                              onChange={(date: Date) =>
                                this.handleUpdateOperatingHoursStartHour(date, day)
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              popperPlacement="top"
                              portalId="timePickerStart"
                            />

                            <DatePicker
                              testID={`timepicker-end-${day.toLowerCase()}`}
                              customInput={
                                <Input containerStyle={styles.inputTime} />
                              }
                              selected={this.state.operatingHours[day.toLowerCase()]?.end_hour}
                              onChange={(date: Date) =>
                                this.handleUpdateOperatingHoursEndHour(date, day)
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              popperPlacement="top"
                              portalId="timePickerEnd"
                            />
                          </View>


                        </View>
                      )
                    })
                  }
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Link to website</Typography>
                  <Input 
                    testID="txtInputLinkWebsite"
                    containerStyle={styles.input}
                    value={this.state.linkWebsite}
                    {...this.txtInputLinkWebsiteProps}
                  />
                </View>

                <View style={styles.field}>
                  <Typography variant="sm" style={styles.label}>Phone</Typography>
                  <Input 
                    testID="txtInputPhone"
                    containerStyle={styles.input}
                    value={this.state.phone_number}
                    {...this.txtInputPhoneProps}
                  />
                </View>


                {
                  this.state.isPremium && (
                    <>     
                      <Typography variant="sm" style={styles.accountActionsTitle}>Account actions</Typography>

                      <View style={styles.divider} /> 

                      <View style={styles.field}>
                        <Typography variant="sm" style={styles.label}>Facebook</Typography>
                        <Input 
                          testID="txtInputFacebook"
                          containerStyle={styles.input}
                          value={this.state.facebook}
                          {...this.txtInputFacebookProps}
                        />
                      </View>


                      <View style={styles.field}>
                        <Typography variant="sm" style={styles.label}>Instagram</Typography>
                        <Input 
                          testID="txtInputInstagram"
                          containerStyle={styles.input}
                          value={this.state.instagram}
                          {...this.txtInputInstagramProps}
                        />
                      </View>
                    </>
                  )
                }

                <>
                  <Typography variant="sm" style={styles.accountActionsTitle}>Business questions</Typography>

                  <View style={styles.divider} /> 
                  {
                    this.state.businessQuestionsList.map(question => {
                      if (question.question_type === "Dropdown") {
                        return (
                          <View key={question.id} style={{...styles.field, zIndex: 99 }}>
                            <Typography variant="sm" style={styles.label}>{question.question}</Typography>
                            <Select 
                              style={{ fontFamily: "Inter" }}
                              options={question.options?.map(option => { return { label: option.name, value: String(option.id) }})}
                              styles={{
                                control: (baseStyle) => ({
                                  ...baseStyle,
                                  borderRadius: 10,
                                  height: 44,
                                }),
                                container: (baseStyle) => ({
                                  ...baseStyle,
                                  fontFamily: "Inter",
                                }),    
                              }}
                              testID={`dropdownQuestion-${question.id}`}
                              value={this.state.businessAnswersDropdownList.find(anwser => anwser.question_id === question.id)?.answer}
                              onChange={(value) => this.handleUpdateDropDownBusinessAnwser(question.id, value)}
                            />
                          </View>
                        )
                      } else {
                        return (
                          <View key={question.id} style={styles.field}>
                            <Typography variant="sm" style={styles.label}>{question.question}</Typography>
                              <Input
                                containerStyle={styles.input}
                                testID={`txtInputQuestion-${question.id}`}
                                placeholderTextColor={"#94A3B8"}
                                value={this.state.businessAnswersList.find(anwser => anwser.question_id === question.id)?.answer}
                                onChangeText={(text) => this.handleUpdateBusinessAnwser(question.id, text)}
                              />
                          </View>
                        )
                      }
                    })
                  }
                </>


                <Button 
                  testID="btnSaveChanges"
                  textStyle={styles.buttonText}
                  style={styles.button}
                  loading={this.state.loading}
                  text={this.state.loading ? "" : "Save changes"}
                  {...this.btnSaveChangesProps}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    ); // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%", 
    height: "100%", 
    backgroundColor: "#9BCE7B",
    flex: 1,
  },
  scroll: {
    backgroundColor: "#9BCE7B",
  },
  main: {
    position: "relative",
    flex: 1, 
    display: "flex", 
    marginHorizontal: "auto",
    marginVertical: 50,
    width: "100%", 
    height: "100%", 
    maxWidth: 900,
  },
  alertContainer: {
    paddingVertical: 21, 
    paddingHorizontal: 16, 
    width: "100%", 
    display: "flex", 
    alignItems: "flex-start", 
    backgroundColor: "#FEE2E2", 
    borderRadius: 4, 
    borderLeftWidth: 4, 
    borderLeftColor: "#DC2626", 
    marginBottom: 48
  },
  alertText: {
    fontFamily: "Inter",
    color: "#DC2626", 
    fontSize: 12
  },
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center"
  },
  goBackButton: {
    width: 32,
    height: 32,
    borderRadius: 100,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  goBackButtonIcon: {
    fontSize:  24,
    color: "#0F172A"
  },
  headerText: {
    fontWeight: "700",
    color: "#1E293B",
    marginLeft: 8
  },
  content: {
    marginTop: 50,
    paddingHorizontal: 30,
    flexDirection: "row",
    justifyContent: "space-between"
  },
  uploadContainer: {
    width: 412,
  },
  uploadButtton: {
    width: "100%",
    height: 254,
    padding: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E2E8F0",
    borderStyle: "solid",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF4D"
  },
  uploadedImageContainerList: {
    flexDirection: "row",
    marginTop: 30,
    flexWrap: "wrap"
  },
  uploadedImageContainer: {
    position: "relative",
    width: 54,
    height: 54,
    borderRadius: 8,
    marginRight: 12,
    marginTop: 12
  },
  uploadedImage: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  removeUploadedImageButton: {
    position: "absolute",
    top: -6,
    right: -6,
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: "#FFFFFF"
  },
  removeUploadedImageButtonIcon: {
    fontSize: 16,
    color: "#DC2626"
  },
  couldIcon: {
    fontSize: 36,
    color: "#0F172A"
  },
  uploadTitle: {
    marginTop: 16,
    color: "#0F172A",
    fontWeight: "700"
  },
  uploadSubTitle: {
    fontWeight: "400",
    color: "#64748B",
    marginTop: 4
  },
  uploadHighlightContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16
  },
  uploadHighlightIcon: {
    fontSize: 20,
    color: "#5F05FF"
  },
  uploadHighlightText: {
    fontWeight: "700",
    color: "#5F05FF",
    marginLeft: 8
  },
  formContainer: {
    width: 327,
  },
  field: {
    marginBottom: 24
  },
  accountActionsTitle: {
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#64748B",
    marginBottom: 8
  },
  divider: {
    width: "100%",
    height:  1,
    backgroundColor: "#E2E8F0",
    marginBottom: 16
  },
  labelContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  label: {
    fontWeight: "700",
    color: "#334155",
    marginBottom: 4
  },
  labelChangePasswordText: {
    fontWeight: "700",
    color: "#8861A9",
    marginBottom: 4
  },
  input: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  },
  inputTime: {
    maxWidth: 70,
    width: "100%",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  },
  textareaContainer: {
    width: "100%",
    height: 120,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  },
  textarea: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    ...Platform.select({
      web: {
        borderWidth: 0, 
        outlineStyle: "none"
      },
      default: {}
    })
  },
  bioLengthText: {
    fontWeight: "400",
    color: "#334155",
    marginLeft: "auto",
    marginTop: 2
  },
  button: {
    marginTop: 12,
    backgroundColor: "#8861A9"
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 16
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
})
// Customizable Area End
